<template>
  <div class="organizaStr">
    <img :src="postData.topicInteriorView" alt="" />
  </div>
</template>

<script>
import { getTopicInfoByIdURL } from "../api";
import { getHashParam } from "@/utils/utils.js";
import { gloabalCount, toRegister } from "@/utils/common";
export default {
  name: "organizaStr",
  data() {
    return {
      id: "",
      oIndex: 1,
      postData: {
        topicInteriorView: "",
      },
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    const { id } = this.$route.query;
    this.id = id;
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    async getInfo(id) {
      let params = {
        id: this.id,
        userId: this.userId,
      };
      let res = await this.$axios.get(`${getTopicInfoByIdURL}`, {
        params: params,
      });
      if (res.code == 200) {
        this.postData = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.organizaStr {
  min-height: 100vh;
  padding: 18px 32px;
  img {
    width: 100%;
  }
}
</style>
